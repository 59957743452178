import { createAsyncThunk,createSlice } from '@reduxjs/toolkit';
import { Category, getCategoryBySlugApi, getHomepageMovies, getMovieByPlaySlugApi, getMovieBySlugApi, Movie } from '../../apis/movie.api';

export interface MovieSlice {
    homepageCategories: Category[];
    selectedMovie: Movie | null;
    selectedCategory: Category | null;
}

const initialState: MovieSlice = {
    homepageCategories: [],
    selectedMovie: null,
    selectedCategory: null
};

export const getHomepageCategories = createAsyncThunk('movie/homepageCategories', async () => {
    const resp = await getHomepageMovies();

    return resp.result.items
});

export const getMovieBySlug = createAsyncThunk('movie/getMovieBySlug', async (movieSlug: string) => {
    const resp = await getMovieBySlugApi(movieSlug);

    return resp.result
});

export const getMovieByPlaySlug = createAsyncThunk('movie/getMovieByPlaySlug', async (playSlug: string) => {
    const resp = await getMovieByPlaySlugApi(playSlug);

    return resp.result
});

export const getCategory = createAsyncThunk('movie/getCategoryBySlug', async (param: {slug: string, page: number}) => {
    const resp = await getCategoryBySlugApi(param.slug, param.page);

    return resp.result
});

const movieSlice = createSlice({
  name: 'homepage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHomepageCategories.fulfilled, (state, action) => {
        state.homepageCategories = action.payload
    });

    builder.addCase(getMovieBySlug.fulfilled, (state, action) => {
        state.selectedMovie = action.payload
    });

    builder.addCase(getMovieByPlaySlug.fulfilled, (state, action) => {
        state.selectedMovie = action.payload
    });

    builder.addCase(getCategory.fulfilled, (state, action) => {
        state.selectedCategory = action.payload
    });
  },
});

export default movieSlice.reducer;