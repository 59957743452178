import axios from 'axios';

export const HttpApi = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

// HttpApi.interceptors.request.use((config: any) => { // TODO: Refactor type
//     config.headers = { ...config.headers, Authorization: `Bearer ${readToken()}` };

//     return config;
// });

// HttpApi.interceptors.response.use(undefined, (error: AxiosError<{ message: string }>) => {

//     console.log('AAAAAA');
//     console.log(error.response?.status);
//     console.log(error.response?.status == 401);

//     // if (error.response?.status == 401) {
//     //     console.log(window.location.host);
//     //     window.location.replace(window.location.host + '/dang-nhap');        
//     // }

//     throw new ApiError<ApiErrorData>(error.response?.data?.message || error.message);
// });

// export interface ApiErrorData {
//     status: string;
//     message: string;
//     result: null;
// }
