import { createAction, createAsyncThunk, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { User } from '../../types/user.type';
import { persistUser, readUser } from '../../services/local-storgage.service';
import { APP_CONFIG } from '../../config';

export interface CustomizationState {
    isOpen: any[], // for active default menu
    defaultId: string,
    fontFamily: string,
    borderRadius: number,
    opened: boolean
}

const initialState: CustomizationState = {
    isOpen: [], // for active default menu
    defaultId: 'default',
    fontFamily: APP_CONFIG.FONT_FAMILY,
    borderRadius: APP_CONFIG.BORDER_RADIUS,
    opened: true
};

export const openMenu = createAction<string>('customization/openMenu');

export const setMenu = createAction<boolean>('customization/setMenu');


export const customizationSlice = createSlice({
    name: 'customization',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(openMenu, (state, action) => {
            state.isOpen = [action.payload]
        });
        builder.addCase(setMenu, (state, action) => {
            state.opened = action.payload
        });
    }
});

export default customizationSlice.reducer;
