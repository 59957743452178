import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const HomePage = React.lazy(() => import('../pages/homepage/home.page'));
const HomeLayout = React.lazy(() => import('../layouts/home.layout'));
const WatchingPage = React.lazy(() => import('../pages/watching/watching.page'));
const ListMoviePage = React.lazy(() => import('../pages/list-movie/list-movie.page'));

// routes

// ==============================|| ROUTING RENDER ||============================== //

export const AppRouter: React.FC = () => {
    return (
        <BrowserRouter>
            <Suspense>
                <Routes>
                    <Route path="" element={<HomeLayout />}>
                        <Route path="" element={<HomePage />} />
                    </Route>
                    <Route path="/the-loai" element={<HomeLayout />}>
                        <Route path=":slug" element={<ListMoviePage />} />
                        <Route path=":slug/page/:page" element={<ListMoviePage />} />
                    </Route>
                    <Route path="/quoc-gia" element={<HomeLayout />}>
                        <Route path=":slug" element={<ListMoviePage />} />
                        <Route path=":slug/page/:page" element={<ListMoviePage />} />
                    </Route>
                    <Route path="/xem-phim" element={<HomeLayout />}>
                        <Route path=":slug" element={<WatchingPage />} />
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}