import { APP_CONFIG } from '../config';
import { ApiListResp, ApiResp } from '../types/api-resp.type';
import { HttpApi } from './http/http.api';


export interface Actor {
    id: number;
    name: string;
    thumbnail: string;
}

export interface Tag {
    id: number;
    name: string;
}

export interface Episode {
    id: number;
    movie_id: number;
    name: string;
    slug: string;
    play_url: string;
    is_selected: boolean;
}

export interface Movie {
    id: number;
    name: string;
    slug: string;
    fullname: string;
    subname: string;
    play_url: string | null;
    thumbnail: string;
    description: string;
    score: string;
    director: string;
    country: string;
    year: string

    categories?: Category[];
    actors?: Actor[];
    tags?: Tag[];
    episodes?: Episode[];
    selected_episode?: Episode | null;

    related_movies?: Movie[];
    hot_movies?: Movie[];
}

export interface Category {
    id: number;
    parent_id: string | null;
    name: string;
    slug: string;
    movies?: Movie[];
    total_movies?: number;
}

export const getHomepageMovies = (): Promise<ApiListResp<Category>> =>
    HttpApi.get<ApiListResp<Category>>(`${APP_CONFIG.API_URL}/homepage`).then(({ data }) => data);

export const getMovieBySlugApi = (movieSlug: string): Promise<ApiResp<Movie>> =>
    HttpApi.get<ApiResp<Movie>>(`${APP_CONFIG.API_URL}/${movieSlug}`).then(({ data }) => data);

export const getMovieByPlaySlugApi = (playSlug: string): Promise<ApiResp<Movie>> =>
    HttpApi.get<ApiResp<Movie>>(`${APP_CONFIG.API_URL}/xem-phim/${playSlug}`).then(({ data }) => data);

export const getCategoryBySlugApi = (slug: string, page: number): Promise<ApiResp<Category>> =>
    HttpApi.get<ApiResp<Category>>(`${APP_CONFIG.API_URL}/category?slug=${slug}&page=${page}`).then(({ data }) => data);

